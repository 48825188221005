import axios from 'axios';
import humps from 'humps';

export async function getRequest(path: string, username?: string, password?: string): Promise<any> {
        let API_DOMAIN = process.env.REACT_APP_API_DOMAIN
    var newHeaders: any = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    }

    if (username && password) {
        const basicAuth = btoa(`${username}:${password}`);
        newHeaders['Authorization'] = `Basic ${basicAuth}`;
    }

    return await axios.get(API_DOMAIN + path, { headers: newHeaders })
        .then(
            (response: any) => {
                const apiResponse: any = {
                    status: response.status,
                    body: humps.camelizeKeys(response.data),
                };
                return apiResponse;
            },
            (error: any) => {
                console.log(error)
            },
        );
}