/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./App.css";
import { getRequest } from "./common/getRequest";
import Cookies from "js-cookie";
import { toastUtil } from "./common/toastUtil";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
export function number_format(
  number: any,
  decimals?: any,
  dec_point?: any,
  thousands_sep?: any
) {
  var n = number,
    c = isNaN((decimals = Math.abs(decimals))) ? 2 : decimals;
  var d = dec_point === undefined ? "," : dec_point;
  var t = thousands_sep === undefined ? "." : thousands_sep,
    s = n < 0 ? "-" : "";
  var i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "",
    j: number = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t)
  );
}

function App() {
  const [data, setData] = useState<any>(null);
  const [isActiveReport, setIsActiveReport] = useState<boolean>(
    Cookies.get("isLoggedIn") === "true"
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [username, setUsername] = useState<any>(Cookies.get("username"));
  const [password, setPassword] = useState<any>(Cookies.get("password"));

  async function fetchData() {
    try {
      const resultStats: any = await getRequest(
        "/omlife_stats/v1/stats",
        username,
        password
      );
      if (resultStats.status === 200) {
        setIsActiveReport(true);
        setData(resultStats.body);

        const expirationTime = 10; // 10 phút
        Cookies.set("isLoggedIn", true as any, {
          expires: expirationTime / (24 * 60),
        });
        Cookies.set("username", username as any, {
          expires: expirationTime / (24 * 60),
        });
        Cookies.set("password", password as any, {
          expires: expirationTime / (24 * 60),
        });
      } else {
        toastUtil.error("Sai thông tin đăng nhập", 2);
        setUsername("");
        setPassword("");
      }
    } catch (error: any) {
      toastUtil.error("Sai thông tin đăng nhập", 2);
      setUsername("");
      setPassword("");
    }
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Tháng bắt đầu từ 0 (0 - 11)
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  const year = currentDate.getFullYear();
  const handleKeyPress = async (event: any) => {
    if (event.key === "Enter") {
      await fetchData();
    }
  };
  useEffect(() => {
    if (username && password) {
      fetchData();
    }
  }, []);
  return (
    <div className="report_container">
      {!isActiveReport ? (
        <div className="login-form">
          <h2>Đăng nhập</h2>
          <p className="text_form" style={{ marginBottom: "28px" }}>
            Báo cáo số liệu Omfarm
          </p>
          <div>
            <div className="form-group">
              <label className="text_form" htmlFor="username">
                Tên đăng nhập
              </label>
              <input
                type="text"
                value={username}
                onChange={(e: any) => setUsername(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Nhập tên đăng nhập ..."
                required
              />
            </div>
            <div className="form-group">
              <label className="text_form" htmlFor="password">
                Mật khẩu
              </label>
              <input
                type="password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Nhập mật khẩu ..."
                required
              />
            </div>
            <button
              className="button_login"
              type="submit"
              disabled={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await fetchData();
                setIsLoading(false);
              }}
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                `Đăng nhập`
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="report">
          <div className="header">
            <h1 className="report_header">BÁO CÁO SỐ LIỆU</h1>
            <h2 className="report_title">
              Từ ngày 01/01/{currentYear} - {formattedDay}/{formattedMonth}/
              {year}
            </h2>
            <button
              onClick={() => {
                setIsLoading(true);
                Cookies.remove("isLoggedIn");
                Cookies.remove("username");
                setIsLoading(false);
                setUsername("");
                Cookies.remove("password");
                setPassword("");
                setIsActiveReport(Cookies.get("isLoggedIn") === "true");
              }}
              className="button_logout"
            >
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                `Về trang đăng nhập`
              )}
            </button>
          </div>

          <table className="custom-table">
            <thead>
              <tr>
                <th style={{ width: "700px" }} colSpan={2}>
                  Tiêu chí
                </th>
                <th style={{ width: "250px" }}>Số lượng</th>
                <th>Cách tính</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={2}>Số lượng truy cập</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.soLuongTruyCap)}
                </td>
                <td>Tính từ thời điểm hoạt động đến thời điểm thống kê</td>
              </tr>

              <tr>
                <td rowSpan={2}>Số lượng người bán</td>
                <td>Tổng số người bán</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.soNguoiBan)}
                </td>
                <td>Tính từ thời điểm hoạt động đến thời điểm thống kê</td>
              </tr>
              <tr>
                <td>Số lượng người bán mới</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.soNguoiBanMoi)}
                </td>
                <td>
                  Tính thời điểm năm báo cáo từ 01/01/{currentYear} đến thời
                  điểm báo cáo
                </td>
              </tr>
              <tr>
                <td rowSpan={2}>Số lượng sản phầm</td>
                <td>Tổng số sản phẩm (SKU)</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.tongSoSanPham)}
                </td>
                <td>Tính từ thời điểm hoạt động đến thời điểm thống kê</td>
              </tr>
              <tr>
                <td>Số lượng sản phẩm mới</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.soSanPhamMoi)}
                </td>
                <td>
                  Tính thời điểm năm báo cáo từ 01/01/{currentYear} đến thời
                  điểm báo cáo
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>Số lượng giao dịch</td>
                <td>Tổng số đơn hàng</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.soLuongGiaoDich)}
                </td>
                <td>
                  Tính thời điểm năm báo cáo từ 01/01/{currentYear} đến thời
                  điểm báo cáo
                </td>
              </tr>
              <tr>
                <td>Tổng số đơn hàng thành công</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.tongSoDonHangThanhCong)}
                </td>
                <td>
                  Tính thời điểm năm báo cáo từ 01/01/{currentYear} đến thời
                  điểm báo cáo
                </td>
              </tr>
              <tr>
                <td>Tổng số đơn hàng không thành công</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.tongSoDongHangKhongThanhCong)}
                </td>
                <td>
                  Tính thời điểm năm báo cáo từ 01/01/{currentYear} đến thời
                  điểm báo cáo
                </td>
              </tr>
              <tr>
                <td colSpan={2}>Tổng giá trị giao dịch</td>
                <td style={{ textAlign: "center" }}>
                  {number_format(data?.tongGiaTriGiaoDich)} (VNĐ)
                </td>
                <td>
                  Tổng số này ứng với tổng số đơn hàng giao dịch thành công
                  (Tính thời điểm năm báo cáo từ 01/01/{currentYear} đến thời
                  điểm báo cáo)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default App;
